import { useFormik } from 'formik'
import { createContext, FC, ReactNode, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

export type WizardProviderProps = {
  children: ReactNode
}

const initialValues = {
  websiteName: '',
  style: ''
}

export type WizardProviderValue = {
  formik: ReturnType<typeof useFormik<typeof initialValues>>
  validators: {
    isFirstStepValid: boolean
    isFourthStepValid: boolean
  }
}

const WizardContext = createContext<WizardProviderValue | null>(null)

export const WizardProvider: FC<WizardProviderProps> = ({ children }) => {
  const { t } = useTranslation()

  const FormSchema = object().shape({
    websiteName: string()
      .trim()
      .max(250)
      .required(t('form.validation.isRequired')),
    style: string().oneOf(['modern', 'dark', 'elegant', 'minimal']).required()
  })

  const onSubmit = () => {
    // TODO
  }

  const formik = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit,
    validateOnMount: true
  })

  const validators = useMemo(
    () => ({
      isFirstStepValid: formik.errors.websiteName === undefined,
      isFourthStepValid: formik.errors.style === undefined
    }),
    [formik.errors.websiteName, formik.errors.style]
  )

  const ctxValue = useMemo(() => ({ formik, validators }), [formik, validators])

  return (
    <WizardContext.Provider value={ctxValue}>{children}</WizardContext.Provider>
  )
}

export const useWizardContext = () => {
  const ctxValue = useContext(WizardContext)

  if (!ctxValue) {
    throw new Error('useWizardContext must be used inside WizardProvider')
  }

  return ctxValue
}
