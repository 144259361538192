import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'

export const CHRISTMAS_SALE = 'CHRISTMAS_SALE'
export const PROMOTION_3M = 'PROMOTION_3M'
export const SUMMER_SALE = 'SUMMER_SALE'
export const PROMOTION_FOR_FREE_PLAN = 'PROMOTION_FOR_FREE_PLAN'

export const PROMOTION_3M_END_DATE = '2024-08-31'
export const PROMOTION_3M_START_DAY = 15

// TODO change in on real date
export const PROMOTION_FOR_FREE_PLAN_START_DAY = '2024-09-16'

export const useSale = () => {
  const hasAccessToPromotion12M = useFeatureFlags('PROMOTION_12M')
  const PROMOTION_3M_FF = useFeatureFlags('PROMOTION_3M')
  const hasAccessToDiscounts = useAcl('account_settings.invoices.view_page')
  const hasAccessToPromotionForFreePlan = useFeatureFlags(
    'PROMOTION_FOR_FREE_PLAN'
  )

  if (!hasAccessToDiscounts) {
    return null
  }

  // change on CHRISTMAS_SALE if needed
  if (hasAccessToPromotion12M) {
    return SUMMER_SALE
  }

  if (hasAccessToPromotionForFreePlan) {
    return PROMOTION_FOR_FREE_PLAN
  }

  if (PROMOTION_3M_FF) {
    return PROMOTION_3M
  }

  return null
}
