import { LANDINGS } from '@routes/path'
import { useLocation, useNavigate } from 'react-router-dom'

const useSteps = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const stepMap: { [key: string]: number } = {
    [LANDINGS.LANDINGS_CREATE_WIZARD_FIRST]: 1,
    [LANDINGS.LANDINGS_CREATE_WIZARD_SECOND]: 2,
    [LANDINGS.LANDINGS_CREATE_WIZARD_THIRD]: 3,
    [LANDINGS.LANDINGS_CREATE_WIZARD_FOURTH]: 4,
    [LANDINGS.LANDINGS_CREATE_WIZARD_FIFTH]: 5,
    [LANDINGS.LANDINGS_CREATE_WIZARD_SIXTH]: 6
  }

  const stepNumber = stepMap[location.pathname]

  const nextStepUrl = Object.keys(stepMap).find(
    key => stepMap[key] === stepNumber + 1
  )

  const nextStep = () => {
    if (nextStepUrl) {
      navigate(nextStepUrl)
    }
  }

  const stepsLimit = Object.keys(stepMap).length

  return { stepNumber, nextStep, stepsLimit }
}

export default useSteps
