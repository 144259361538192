import Dark from '@images/landings/wizard/dark.png'
import Elegant from '@images/landings/wizard/elegant.png'
import Minimal from '@images/landings/wizard/minimal.png'
import Modern from '@images/landings/wizard/modern.png'
import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import useSteps from '@pages/Landings/routes/Landings/CreateLandingWizard/helpers/useSteps'
import {
  BoxOutline,
  BoxOutlineProvider,
  Button,
  Heading,
  Image,
  Paragraph,
  Radio,
  Spacer
} from '@ui-kit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { StyleBox } from './components/StyleBox'
import styles from './Fourth.module.scss'

export const FourthStep: React.FC = () => {
  const { t } = useTranslation()
  const { formik, validators } = useWizardContext()
  const { values, handleChange, setFieldValue } = formik
  const { websiteName } = values
  const { isFourthStepValid } = validators
  const { nextStep } = useSteps()

  return (
    <div>
      <Heading level={1} weight={600}>
        {t('landings.create.wizard.landing.modal.what.is.your.style')}
      </Heading>

      <Spacer space={20} />

      <Paragraph size={14} color='neutral-6'>
        {t(
          'landings.create.wizard.landing.modal.our.designers.have.thoughtfully'
        )}
      </Paragraph>

      <Spacer space={20} />

      <div className={styles['fourth-step__grid']}>
        <StyleBox lpStyle='modern' />
        <StyleBox lpStyle='dark' />
        <StyleBox lpStyle='minimal' />
        <StyleBox lpStyle='elegant' />
      </div>

      <Spacer space={30} />
      <Row justifyContent='end'>
        <Button
          icon='icon-arrow-right'
          disabled={!isFourthStepValid}
          onClick={nextStep}
        >
          {t('landings.create.wizard.landing.modal.go.to.color.palletes')}
        </Button>
      </Row>
    </div>
  )
}
