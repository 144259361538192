import { Hint } from '@ui-kit'
import React from 'react'

import useSteps from '../helpers/useSteps'

interface SecondColumnContentProps {}

const hints = [
  [
    'Naming your website enhances your ability to manage it 1',
    'Naming your website enhances your ability to manage it 2',
    'Naming your website enhances your ability to manage it 3'
  ],
  [
    'jestem podpowiedzia do drugiego pytania, wylosowano opcje numer 1',
    'jestem podpowiedzia do drugiego pytania, wylosowano opcje numer 2'
  ],
  [
    'jestem podpowiedzia do trzeciego pytania, wylosowano opcje numer 1',
    'jestem podpowiedzia do trzeciego pytania, wylosowano opcje numer 2'
  ],
  [
    'jestem podpowiedzia do czwartego pytania, wylosowano opcje numer 1',
    'jestem podpowiedzia do czwartego pytania, wylosowano opcje numer 2'
  ]
]

const SecondColumnContent: React.FC<SecondColumnContentProps> = () => {
  const { stepNumber } = useSteps()
  const hintText =
    hints[stepNumber - 1]?.[
      Math.floor(Math.random() * hints[stepNumber - 1].length)
    ]

  return <Hint>{hintText}</Hint>
}

export default SecondColumnContent
